export const zgkCitesOptions = [
	{
		value: '//бригантина.кино-75.рф',
		title: 'Бригантина, Чита',
	},
	{
		value: '//нерча.кино-75.рф',
		title: 'Нерча, Нерчинск',
	},
	{
		value: '//авангард.кино-75.рф',
		title: 'Авангард, Кокуй',
	},
	{
		value: '//восток.кино-75.рф',
		title: 'Восток, Борзя',
	},
	{
		value: '//космос.кино-75.рф',
		title: 'Космос, Балей',
	},
	{
		value: '//россия.кино-75.рф',
		title: 'Россия, Первомайский',
	},
	{
		value: '//экран.кино-75.рф',
		title: 'Экран, Карымское',
	},
	{
		value: '//даурия.кино-75.рф',
		title: 'Даурия, Краснокаменск',
	},
	{
		value: '//островского.кино-75.рф',
		title: 'Кинозал на Островского, Чита'
	}
]